import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { GetSupplierInsuranceRenewalDatesResponse } from '../../../../../Models/Jobs/EditJob/GetSupplierInsuranceRenewalDatesResponse';

export const useGetSupplierInsuranceRenewalDates = (
  options?: any
): UseMutationResult<
  GetSupplierInsuranceRenewalDatesResponse,
    any,
    number
  > => {
    const supplierInsuranceRenewalDates = (memberId: number) => {
      return agent.members.getSupplierInsuranceRenewalDates(memberId);
    };

    return useMutation(supplierInsuranceRenewalDates, options);
};
