import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { UnassignSupplyingMemberRequest } from '../../../../../Models/Jobs/EditJob/UnassignSupplyingMemberRequest';
import { MovexErrorResponse } from '../../../../../Types';

export const usePutUnassignSupplyingMember = (): UseMutationResult<string, MovexErrorResponse, UnassignSupplyingMemberRequest> => {
  const putUnassignSupplyingMember = (unassignSupplyingMemberRequest: UnassignSupplyingMemberRequest) => {
    return agent.jobs.putUnassignSupplyingMember(unassignSupplyingMemberRequest);
  };

  return useMutation(putUnassignSupplyingMember);
};
