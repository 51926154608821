import { Tooltip } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import {
  DrivenServiceIcon,
  TransportedServiceIcon
} from "../../../../../Assets/Images";
import { ConfirmDialogProps, Loading } from "../../../../../Components";
import { FormControlKeys } from "../../../../../Components/FormControls";
import {
  ColMd6SectionInner,
  DivContainer,
  FieldSetContainer,
  FieldSetHeader,
  FlexContainer,
  HoverableLink,
  InlineBlockColMd6AndAlignTopSection,
  InlineBlockColMd6Section,
  Margin5LeftSDiv
} from "../../../../../Components/Formik";
import FormikControl from "../../../../../Components/Formik/FormikControl";
import {
  DangerSpanItem,
  LabelItem,
  SpanItem,
  SpanItemNewLine,
  SpanWidth200Item
} from "../../../../../Components/UI";
import { config } from "../../../../../Config/AdminConfig";
import {
  JobViewModel, UpdateAdditionalNotesModel, UpdateDeliveryContact,
  UpdateJobFixedPricesModel,
  UpdateJobGuaranteedPricesModel,
  UpdateJobReferenceModel
} from "../../../../../Models/Jobs/EditJob";
import { SelectedSupplierModel } from "../../../../../Models/Jobs/EditJob/GeneralJobRequest/SelectedSuppliersModel";
import { RouteConstants } from "../../../../../Routes";
import {
  ConfirmDialogContent,
  getFormattedDateWithYear,
  lookupEnumByValForLabel
} from "../../../../../Utils";
import {
  JobKind,
  JobMovementType,
  JobOfferedTo,
  JobType
} from "../../../../../Utils/Enums";
import {
  usePutUpdateAdditionalNotes,
  usePutUpdateJobDeliveryContact,
  usePutUpdateJobFixedPrices,
  usePutUpdateJobGuaranteedPrices,
  usePutUpdateJobReference
} from "../Hooks";
import { useGetReleaseNotes } from "../Hooks/useGetReleaseNotes";
import { EditJobKeys, JobDetailsJobKeys } from "../Types";
import { useJobStatus } from "../useJobStatus";

type GeneralFormProps = {
  job: JobViewModel;
  suppliers: SelectedSupplierModel[];
  onConfirmDialogClose: () => void;
  onHandleDialogProps: (dialogProps: ConfirmDialogProps) => void;
  showAlert: Function;
};

const Section3JobDetails: React.FC<GeneralFormProps> = (props): JSX.Element => {
  /** Props */
  const {
    job,
    suppliers,
    onConfirmDialogClose,
    onHandleDialogProps,
    showAlert
  } = props;

  const { Id: jobId } = job;

  /** States Hooks */
  const [formikInitialValues, setFormikInitialValues] = useState<JobViewModel>(job);

  /** Query Hooks */
  const {
    mutate: putUpdateJobReferenceMutate,
    data: putUpdateJobReferenceData,
    error: putUpdateJobReferenceError,
  } = usePutUpdateJobReference();

  const {
    mutate: putUpdateJobDeliveryContactMutate,
    data: putUpdateJobDeliveryContactData,
    error: putUpdateJobDeliveryContactError,
  } = usePutUpdateJobDeliveryContact();

  const {
    mutate: putUpdateJobFixedPrices,
    data: putUpdateJobFixedPricesData,
    error: putUpdateJobFixedPricesError,
  } = usePutUpdateJobFixedPrices();

  const {
    mutate: putJobGuaranteedPrices,
    error: putUpdateJobGuaranteedError,
  } = usePutUpdateJobGuaranteedPrices();

  const {
    mutate: putUpdateAdditionalNotesMutate,
    data: putUpdateAdditionalNotesData,
    error: putUpdateAdditionalNotesError,
  } = usePutUpdateAdditionalNotes();

  const {
    isLoading: isGetReleaseNotesLoading,
    data: getReleaseNotesDataResponse,
    refetch: fetchGetReleaseNotes,
    error: getReleaseNotesError,
  } = useGetReleaseNotes(job?.Id ?? 0, {
    enabled: false, // Disable the query by default
    refetchOnWindowFocus: false, // Disable refetching on window focus
  });

  const {
    jobCompleted,
    jobNotCompleted,
    isDrivenOrBoth,
    isGuaranteedPrice,
    isFixedPriceOrBothOrGuaranteedPrice,
    isTransportedOrBoth } = useJobStatus(job);

  /* useEffect */
  useEffect(() => {
    if (putUpdateJobReferenceData) {
      showAlert('Job Reference', "Job Reference has been updated.");
    }
  }, [putUpdateJobReferenceData]);

  useEffect(() => {
    if (putUpdateJobDeliveryContactData) {
      showAlert('Job Update Delivery Phone', putUpdateJobDeliveryContactData);
    }
  }, [putUpdateJobDeliveryContactData]);

  useEffect(() => {
    if (putUpdateJobFixedPricesData) {
      showAlert('Price Updated', putUpdateJobFixedPricesData);
    }
  }, [putUpdateJobFixedPricesData]);

  useEffect(() => {
    if (putUpdateAdditionalNotesData) {
      showAlert('Update Additional Notes', putUpdateAdditionalNotesData);
    }
  }, [putUpdateAdditionalNotesData]);

  useEffect(() => {
    setFormikInitialValues(job);
  }, [job]);

  useEffect(() => {
    if (putUpdateJobReferenceError) {
      showAlert(putUpdateJobReferenceError?.Subject, putUpdateJobReferenceError?.Description);
    } else if (putUpdateJobDeliveryContactError) {
      showAlert(putUpdateJobDeliveryContactError?.Subject, putUpdateJobDeliveryContactError?.Description);
    } else if (putUpdateJobFixedPricesError) {
      showAlert(putUpdateJobFixedPricesError?.Subject, putUpdateJobFixedPricesError?.Description);
    } else if (putUpdateJobGuaranteedError) {
      showAlert(putUpdateJobGuaranteedError?.Subject, putUpdateJobGuaranteedError?.Description);
    } else if (putUpdateAdditionalNotesError) {
      showAlert(putUpdateAdditionalNotesError?.Subject, putUpdateAdditionalNotesError?.Description);
    } else if (getReleaseNotesError) {
      showAlert(getReleaseNotesError?.Subject, getReleaseNotesError?.Description);
    }

  }, [putUpdateJobReferenceError,
    putUpdateJobDeliveryContactError,
    putUpdateJobFixedPricesError,
    putUpdateJobGuaranteedError,
    putUpdateAdditionalNotesError,
    getReleaseNotesError]);

  /** Functions */
  const formik = useFormik({
    initialValues: formikInitialValues,
    onSubmit: () => { },
    enableReinitialize: true,
  });
  const { values } = formik;

  const handlePutUpdateJobReference = () => {
    let payload: UpdateJobReferenceModel = {
      JobId: jobId,
      Reference: values.Reference ? values.Reference : '',
    };
    putUpdateJobReferenceMutate(payload);
  };

  const handlePutJobDeliveryContact = () => {
    let payload: UpdateDeliveryContact = {
      JobId: jobId,
      DeliveryContact: values.DeliveryContact,
      DeliveryPhone: values.DeliveryPhone,
    };
    putUpdateJobDeliveryContactMutate(payload);
  };

  const handlePutUpdateJobDFixedPriceMaxPrices = useCallback(() => {
    let payload: UpdateJobFixedPricesModel = {
      JobId: jobId,
      DrivenFixedMaximumPrice: values.DFixedPriceMax_Original,
    };
    putUpdateJobFixedPrices(payload);
  }, [jobId, values.DFixedPriceMax_Original, putUpdateJobFixedPrices]);

  const handlePutUpdateJobTFixedPriceMinPrices = useCallback(() => {
    let payload: UpdateJobFixedPricesModel = {
      JobId: jobId,
      TransportedFixedMinimumPrice: values.TFixedPriceMin_Original,
    };
    putUpdateJobFixedPrices(payload);
  }, [jobId, values.TFixedPriceMin_Original, putUpdateJobFixedPrices]);

  const handlePutUpdateJobTFixedPriceMaxPrices = useCallback(() => {
    let payload: UpdateJobFixedPricesModel = {
      JobId: jobId,
      TransportedFixedMaximumPrice: values.TFixedPriceMax_Original,
    };
    putUpdateJobFixedPrices(payload);
  }, [jobId, values.TFixedPriceMax_Original, putUpdateJobFixedPrices]);

  const handlePutUpdateDrivenJobGuaranteedPrices = () => {
    if (values.DGuaranteedPrice) {
      let payload: UpdateJobGuaranteedPricesModel = {
        JobId: jobId,
        DrivenGuaranteedPrice: values.DGuaranteedPrice,
      };
      putJobGuaranteedPrices(payload);
    }
  };
  const handlePutUpdateTransportedJobGuaranteedPrices = () => {
    if (values.TGuaranteedPrice) {
      let payload: UpdateJobGuaranteedPricesModel = {
        JobId: jobId,
        TransportedGuaranteedPrice: values.TGuaranteedPrice,
      };
      putJobGuaranteedPrices(payload);
    }
  };
  const handlePutUpdateAdditionalNotesMutate = () => {
    if (values.AdditionalNotes) {
      let payload: UpdateAdditionalNotesModel = {
        JobId: jobId,
        AdditionalNotes: values.AdditionalNotes,
      };
      putUpdateAdditionalNotesMutate(payload);
    }
  };

  const onHandlePutUpdateJobDFixedPriceMinPrices = useCallback(() => {
    onHandleDialogProps({
      header: ConfirmDialogContent.UpdateDrivenMinimumPrice.Title,
      body: ConfirmDialogContent.UpdateDrivenMinimumPrice.Content,
      show: true,
      confirmCallback: () => {
        let payload: UpdateJobFixedPricesModel = {
          JobId: jobId,
          DrivenFixedMinimumPrice: values.DFixedPriceMin_Original,
        };
        putUpdateJobFixedPrices(payload);
        onConfirmDialogClose();
      },
      cancelCallback: onConfirmDialogClose,
    });
  }, [jobId, values.DFixedPriceMin_Original, onConfirmDialogClose, onHandleDialogProps, putUpdateJobFixedPrices]);

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <br />
          <FieldSetHeader>Job Details</FieldSetHeader>
          <FieldSetContainer>
            <InlineBlockColMd6Section>
              <ColMd6SectionInner>
                <FlexContainer>
                  <LabelItem>Job ID:</LabelItem>
                  <SpanItem>{values.Id}</SpanItem>
                  {values.IsKeyAccountJob && <Tooltip title='Key Account Job'><span>&nbsp;🔑</span></Tooltip>}
                </FlexContainer>
                <div>
                  <DivContainer>
                    <LabelItem>Reference:</LabelItem>
                    {jobCompleted && (
                      <FlexContainer>
                        <FormikControl
                          control={FormControlKeys.LabelledInput}
                          name={JobDetailsJobKeys.Reference}
                          disabled={true}
                        />
                      </FlexContainer>
                    )}
                    {jobNotCompleted && (
                      <FlexContainer>
                        <FormikControl
                          control={FormControlKeys.LabelledInput}
                          name={JobDetailsJobKeys.Reference}
                        />
                        <Margin5LeftSDiv>
                          <FormikControl
                            control={FormControlKeys.Button}
                            label="Update"
                            onClick={() => {
                              handlePutUpdateJobReference();
                            }}
                          />
                        </Margin5LeftSDiv>
                      </FlexContainer>
                    )}
                  </DivContainer>
                </div>

                {job.PartnerID && (
                  <>
                    <LabelItem>Partner ID:</LabelItem>
                    <SpanItem>{job.PartnerID}</SpanItem>
                    <br />
                    <LabelItem>Partner JID:</LabelItem>
                    <SpanItem>{job.PartnerJID}</SpanItem>
                    <br />
                    <LabelItem>Partner CID:</LabelItem>
                    <SpanItem>{job.PartnerCID}</SpanItem>
                  </>
                )}

                <div>
                  <LabelItem>No Of Vehicles:</LabelItem>
                  <SpanItem>{values.NoOfVehicles}</SpanItem>
                  {values.JobKind === JobKind.Return && (
                    <DangerSpanItem>Return</DangerSpanItem>
                  )}
                  {values.JobKind === JobKind.Onward && (
                    <DangerSpanItem>O</DangerSpanItem>
                  )}
                  {values.JobKind === JobKind.MultipleDropOff && (
                    <DangerSpanItem>M</DangerSpanItem>
                  )}
                </div>
                <FlexContainer>
                  <LabelItem>Collect From:</LabelItem>
                  <div>
                    <SpanItemNewLine>{job.CollectionCompany} </SpanItemNewLine>
                    <SpanItemNewLine>{values.CollectionAddress} </SpanItemNewLine>
                    <SpanItemNewLine>{values.CollectionTown}{" "}{values.CollectionPostCode}{" "}</SpanItemNewLine>
                    <SpanItemNewLine>Lat: {values.CollectionLatitude} </SpanItemNewLine>
                    <SpanItemNewLine>Lon: {values.CollectionLongitude} </SpanItemNewLine>
                  </div>
                </FlexContainer>
                <div>
                  <LabelItem>Collection Contact:</LabelItem><SpanItem>{values.CollectionContact}</SpanItem>
                </div>
                <div>
                  <LabelItem>Collection Phone:</LabelItem><SpanItem>{values.CollectionPhone}</SpanItem>
                </div>
                <div>
                  <LabelItem>Specific Date & Time:</LabelItem>
                  <SpanItem>
                    {values.UseExactCollectionDateAndTime ? "true" : "false"}
                  </SpanItem>
                </div>
                <div>
                  <LabelItem>Earliest Collection:</LabelItem>
                  <SpanItem>
                    {values.CollectionDate && getFormattedDateWithYear(values.CollectionDate)}
                  </SpanItem>
                </div>
                <FlexContainer>
                  <LabelItem>Deliver To:</LabelItem>
                  <div>
                    <SpanItemNewLine>{values.DeliveryCompany} </SpanItemNewLine>
                    <SpanItemNewLine>{values.DeliveryAddress} </SpanItemNewLine>
                    <SpanItemNewLine>{values.DeliveryTown} {values.DeliveryPostCode}{" "}</SpanItemNewLine>
                    <SpanItemNewLine>Lat: {values.DeliveryLatitude} </SpanItemNewLine>
                    <SpanItemNewLine>Lon: {values.DeliveryLongitude} </SpanItemNewLine>
                  </div>
                </FlexContainer>
                <div>
                  <DivContainer>
                    <FlexContainer>
                      <LabelItem>Delivery Contact:</LabelItem>
                      <FormikControl disabled={(jobCompleted) ? 'true' : ''} name={JobDetailsJobKeys.DeliveryContact} />
                      <Margin5LeftSDiv>
                        {(!jobCompleted) && (
                          <FormikControl
                            control={FormControlKeys.Button}
                            label="Update"
                            onClick={() => {
                              handlePutJobDeliveryContact();
                            }}
                          />
                        )}
                      </Margin5LeftSDiv>
                    </FlexContainer>
                  </DivContainer>
                </div>

                <div>
                  <DivContainer>
                    <FlexContainer>
                      <LabelItem>Delivery Phone:</LabelItem>
                      <FormikControl disabled={(jobCompleted) ? 'true' : ''} name={JobDetailsJobKeys.DeliveryPhone} />
                      <Margin5LeftSDiv>
                        {(!jobCompleted) && (
                          <FormikControl
                            control={FormControlKeys.Button}
                            label="Update"
                            onClick={() => {
                              handlePutJobDeliveryContact();
                            }}
                          />
                        )}
                      </Margin5LeftSDiv>
                    </FlexContainer>
                  </DivContainer>
                </div>

                <div>
                  <LabelItem>Specific Date & Time:</LabelItem>
                  <SpanItem>
                    {values.UseExactDeliveryDateAndTime ? "true" : "false"}
                  </SpanItem>
                </div>

                <div>
                  <LabelItem>Latest Delivery:</LabelItem>
                  <SpanItem>
                    {getFormattedDateWithYear(values.DeliveryDate)}
                  </SpanItem>
                </div>

                <div>
                  <LabelItem>Driving Distance:</LabelItem>

                  <SpanItem>
                    {values.DistanceMiles && Math.round(values.DistanceMiles)}{" "} miles
                  </SpanItem>
                </div>

                <div>
                  <LabelItem>Movement Type:</LabelItem>
                  <SpanItem>
                    {lookupEnumByValForLabel(JobMovementType, values.JobMovementType ?? JobMovementType.Unknown)}
                  </SpanItem>
                </div>

                <FlexContainer>
                  <LabelItem>Service Type:</LabelItem>
                  <DivContainer>
                    {isDrivenOrBoth && (
                      <DivContainer>
                        <Tooltip title="Driven">
                          <img alt="Driven" src={DrivenServiceIcon} />
                        </Tooltip>
                        <span>{" "} Driven</span>
                        <FlexContainer>
                          {values.JobType === JobType.GuaranteedPrice && (
                            <>
                              <SpanWidth200Item>Guaranteed: </SpanWidth200Item>
                              {jobCompleted && (
                                <FormikControl
                                  name={EditJobKeys.DGuaranteedPrice}
                                  min={0}
                                  control={FormControlKeys.InputGroup}
                                  text="£"
                                  disabled="disabled"
                                />
                              )}
                              {jobNotCompleted && (
                                <FormikControl
                                  name={EditJobKeys.DGuaranteedPrice}
                                  min={0}
                                  control={FormControlKeys.InputGroup}
                                  text="£"
                                />
                              )}
                              {isGuaranteedPrice && (
                                <Margin5LeftSDiv>
                                  <FormikControl
                                    control={FormControlKeys.Button}
                                    label="Update"
                                    onClick={() => {
                                      handlePutUpdateDrivenJobGuaranteedPrices();
                                    }}
                                  />
                                </Margin5LeftSDiv>
                              )}
                            </>
                          )}
                        </FlexContainer>

                        {isFixedPriceOrBothOrGuaranteedPrice && (
                          <>
                            <FlexContainer>
                              <SpanWidth200Item style={{ color: "#468847" }}>Minimum: </SpanWidth200Item>
                              {jobCompleted && (
                                <FormikControl
                                  name={EditJobKeys.DFixedPriceMin}
                                  min={0}
                                  control={FormControlKeys.InputGroup}
                                  text="£"
                                  disabled="disabled"
                                />
                              )}
                              {jobNotCompleted && (
                                <>
                                  <FormikControl
                                    name={EditJobKeys.DFixedPriceMin_Original}
                                    min={0}
                                    control={FormControlKeys.InputGroup}
                                    text="£"
                                  />
                                  <Margin5LeftSDiv>
                                    <FormikControl
                                      control={FormControlKeys.Button}
                                      label="Update"
                                      onClick={onHandlePutUpdateJobDFixedPriceMinPrices}
                                    />
                                  </Margin5LeftSDiv>
                                </>
                              )}
                            </FlexContainer>
                            <FlexContainer>
                              <SpanWidth200Item style={{ color: "#BD321C" }}>Maximum: </SpanWidth200Item>
                              {jobCompleted && (
                                <FormikControl
                                  name={EditJobKeys.DFixedPriceMax_Original}
                                  min={0}
                                  control={FormControlKeys.InputGroup}
                                  text="£"
                                  disabled={true}
                                />
                              )}
                              {jobNotCompleted && (
                                <>
                                  <FormikControl
                                    name={EditJobKeys.DFixedPriceMax_Original}
                                    min={0}
                                    control={FormControlKeys.InputGroup}
                                    text="£"
                                  />
                                  <Margin5LeftSDiv>
                                    <FormikControl
                                      control={FormControlKeys.Button}
                                      label="Update"
                                      onClick={handlePutUpdateJobDFixedPriceMaxPrices}
                                    />
                                  </Margin5LeftSDiv>
                                </>
                              )}
                            </FlexContainer>
                          </>
                        )}
                      </DivContainer>
                    )}
                    {isTransportedOrBoth && (
                      <DivContainer>
                        <Tooltip title="Transported">
                          <img alt="Transported" src={TransportedServiceIcon} />
                        </Tooltip>
                        <span> {" "} Transported</span>

                        <FlexContainer>
                          {values.JobType === JobType.GuaranteedPrice && (
                            <>
                              <SpanWidth200Item>Guaranteed: </SpanWidth200Item>
                              {jobCompleted && (
                                <FormikControl
                                  name={EditJobKeys.TGuaranteedPrice}
                                  min={0}
                                  control={FormControlKeys.InputGroup}
                                  text="£"
                                  disabled="disabled"
                                />
                              )}
                              {jobNotCompleted && (
                                <FormikControl
                                  name={EditJobKeys.TGuaranteedPrice}
                                  min={0}
                                  control={FormControlKeys.InputGroup}
                                  text="£"
                                />
                              )}
                              {isGuaranteedPrice && (
                                <Margin5LeftSDiv>
                                  <Margin5LeftSDiv>
                                    <FormikControl
                                      control={FormControlKeys.Button}
                                      label="Update"
                                      onClick={() => {
                                        handlePutUpdateTransportedJobGuaranteedPrices();
                                      }}
                                    />
                                  </Margin5LeftSDiv>
                                </Margin5LeftSDiv>
                              )}
                            </>
                          )}
                        </FlexContainer>
                        {isFixedPriceOrBothOrGuaranteedPrice && (
                          <>
                            <FlexContainer>
                              <SpanWidth200Item style={{ color: "#468847" }}>Minimum: </SpanWidth200Item>
                              {jobCompleted && (
                                <FormikControl
                                  name={EditJobKeys.TFixedPriceMin_Original}
                                  min={0}
                                  control={FormControlKeys.InputGroup}
                                  text="£"
                                  disabled={true}
                                />
                              )}
                              {jobNotCompleted && (
                                <>
                                  <FormikControl
                                    name={EditJobKeys.TFixedPriceMin_Original}
                                    min={0}
                                    control={FormControlKeys.InputGroup}
                                    text="£"
                                  />
                                  <Margin5LeftSDiv>
                                    <FormikControl
                                      control={FormControlKeys.Button}
                                      label="Update"
                                      onClick={handlePutUpdateJobTFixedPriceMinPrices}
                                    />
                                  </Margin5LeftSDiv>
                                </>
                              )}
                            </FlexContainer>
                            <FlexContainer>
                              <SpanWidth200Item style={{ color: "#BD321C" }}>Maximum: </SpanWidth200Item>
                              {jobCompleted && (
                                <FormikControl
                                  name={EditJobKeys.TFixedPriceMax_Original}
                                  min={0}
                                  control={FormControlKeys.InputGroup}
                                  text="£"
                                  disabled={true}
                                />
                              )}
                              {jobNotCompleted && (
                                <>
                                  <FormikControl
                                    name={EditJobKeys.TFixedPriceMax_Original}
                                    min={0}
                                    control={FormControlKeys.InputGroup}
                                    text="£"
                                  />
                                  <Margin5LeftSDiv>
                                    <FormikControl
                                      control={FormControlKeys.Button}
                                      label="Update"
                                      onClick={handlePutUpdateJobTFixedPriceMaxPrices}
                                    />
                                  </Margin5LeftSDiv>
                                </>
                              )}
                            </FlexContainer>
                          </>
                        )}
                      </DivContainer>
                    )}
                  </DivContainer>
                </FlexContainer>

                <FlexContainer>
                  <LabelItem>Other Info: </LabelItem>
                  <div>
                    {isDrivenOrBoth && (
                      <>
                        Valid MOT/Roadworthy:
                        <SpanItem>
                          {values.Driven_ValidMOT ? " Yes" : " No"}
                        </SpanItem>
                        {values.Driven_FuelRequired !== null && (
                          <>
                            <br />
                            <SpanItem>
                              Fuel Required:
                              {values.Driven_FuelRequired ? " Yes" : " No"}
                            </SpanItem>
                          </>
                        )}
                      </>
                    )}
                    {isTransportedOrBoth && (
                      <>
                        <br />
                        <SpanItem>
                          Drive Onto Transporter:
                          {values.Transported_DriveOnto ? " Yes" : " No"}
                        </SpanItem>
                        <br />
                        <SpanItem>
                          Enclosed Transport Required:
                          {values.Transported_Enclosed ? " Yes" : " No"}
                        </SpanItem>
                      </>
                    )}
                  </div>
                </FlexContainer>

                <FlexContainer>
                  <LabelItem>Offered To:</LabelItem>
                  <div>
                    {values.jobOfferedTo === JobOfferedTo.FiveStarCompanies && (
                      <SpanItem>5 Star Companies</SpanItem>
                    )}
                    {values.jobOfferedTo !== JobOfferedTo.FiveStarCompanies && (
                      values.JobOfferedTo !== undefined &&
                      <SpanItem>{lookupEnumByValForLabel(JobOfferedTo, values.JobOfferedTo)}</SpanItem>
                    )}
                    <br />
                    <SpanItem>
                      {values.JobOfferedTo === JobOfferedTo.SelectedSuppliers && (
                        suppliers.map((supplier) => (
                          <>
                            <SpanItem>
                              <HoverableLink key={supplier.SupplyingMemberID} to={`${RouteConstants.EditMember}/${supplier.SupplyingMemberID}`}>
                                {supplier.UserName}
                              </HoverableLink>
                            </SpanItem>
                            <br />
                          </>
                        ))
                      )}
                    </SpanItem>
                  </div>
                </FlexContainer>

                <div>
                  {values.PromoCode?.trim() !== "" && (
                    <>
                      <LabelItem>Promo Code</LabelItem>
                      <SpanItem>{values.PromoCode}</SpanItem>
                    </>
                  )}
                </div>

                <div>
                  <FlexContainer>
                    <LabelItem>Additional Notes:</LabelItem>
                    {jobNotCompleted &&
                      (<FlexContainer style={{ flexDirection: 'column', flexGrow: 1 }}>
                        <FormikControl
                          control={FormControlKeys.Textarea}
                          name={JobDetailsJobKeys.AdditionalNotes}
                          rows={10}
                          showLabel={false}
                          style={{ resize: 'both' }}
                        />
                        <Margin5LeftSDiv style={{ marginLeft: 'auto' }}>
                          <FormikControl
                            control={FormControlKeys.Button}
                            label="Update"
                            onClick={() => {
                              handlePutUpdateAdditionalNotesMutate();
                            }}
                          />
                        </Margin5LeftSDiv>
                      </FlexContainer>)}
                  </FlexContainer>
                </div>

                <FlexContainer>
                  <LabelItem>Release Notes:</LabelItem>
                  <Loading loading={isGetReleaseNotesLoading} />

                  {values.JobHasReleaseNote === false && !isGetReleaseNotesLoading && (
                    <span>No release notes added</span>
                  )}

                  {values.JobHasReleaseNote === true && getReleaseNotesDataResponse && (
                    <span>{getReleaseNotesDataResponse?.ReleaseNotes}</span>
                  )}

                  {values.JobHasReleaseNote === true && !isGetReleaseNotesLoading && !getReleaseNotesDataResponse && (
                    <Margin5LeftSDiv>
                      <FormikControl
                        control={FormControlKeys.Button}
                        label="Reveal Release Notes"
                        onClick={() => {
                          fetchGetReleaseNotes();
                        }}
                      />
                    </Margin5LeftSDiv>
                  )}
                </FlexContainer>

              </ColMd6SectionInner>
            </InlineBlockColMd6Section>
            <InlineBlockColMd6AndAlignTopSection>
              <ColMd6SectionInner>
                <img
                  alt="GOOGLE MAP"
                  src={
                    "https://maps.googleapis.com/maps/api/staticmap?maptype=roadmap&size=525x475&markers=color:green%7Clabel:A%7C" +
                    values.CollectionLatitude +
                    ", " +
                    values.CollectionLongitude +
                    "&markers=color:red%7Clabel:B%7C" +
                    values.DeliveryLatitude +
                    ", " +
                    values.DeliveryLongitude +
                    " &path=weight:3%7Ccolor:0x2196f3ff%7Cenc:" +
                    encodeURIComponent(String(values.RoutePolyline)) +
                    "&key=" +
                    config.googleAPIKey
                  }
                ></img>
              </ColMd6SectionInner>
            </InlineBlockColMd6AndAlignTopSection>
          </FieldSetContainer>
        </Form>
      </FormikProvider>
    </>
  );
};

export default Section3JobDetails;
