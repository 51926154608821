import axios from 'axios';
import {
    AddMemberFavouriteModel,
    AddMemberRequestModel,
    AddMembersBlockModel, DeleteMemberPartnersRequestModel,
    MembersRequestParam
} from '../../../Models/Members';
import { MemberType, ServiceType } from '../../../Utils/Enums';
import { jsonConfig, responseBody } from '../../Agent';
import { ApiURLConstants } from '../../ApiUrlConstants';
import { GetMembersByRequestFilter } from '../../../Screens/Admin/Billing/AddInvoice/Hooks/useGetMembersListBy';
import { AddTradePlateRequest, AddTransporterPlateRequest } from '../../../Models/Members/EditMember/AddTradePlatesRequest';
import { UpdateTradePlateRequest, UpdateTransporterPlateRequest } from '../../../Models/Members/AddMember/UpdateTradePlateRequest';
import { DeleteTradePlateRequest, DeleteTransporterPlateRequest } from '../../../Models/Members/EditMember/TradePlates/DeleteTradePlates';

const {
    GetConfigObject_URL,
    DeleteMemberBlocks_URL,
    PutAddMembersBlock_URL,
    PostAddConfigObject_URL,
    PutUpdateConfigObject_URL,
    GetSupplyingMembersByUserNameAndServiceType_URL,
    ExportMembersToCsv_URL,
    GetMembers_URL,
    PostAddMember_URL,
    PutUpdateMember_URL,
    GetMember_URL,
    GetNewMembers_URL,
    PostAddSelectedMemberToPartner_URL,
    GetPartnersForDropdown_URL,
    DeleteSelectedPartnersFromMember_URL,
    GetMembersForDropdown_URL,
    PostAddMemberFavourite_URL,
    DeleteMemberFavourites_URL,
    PutUpdateMemberFavourite_URL,
    GetMemberFavourites_URL,
    GetMembersBy_URL,
    PostAddTradePlates_URL,
    PostAddTransporterPlates_URL,
    PutUpdateTransporterPlates_URL,
    PutUpdateTradePlates_URL,
    DeleteTradePlate_URL,
    DeleteTransporterPlate_URL,
    GetSupplierInsuranceRenewalDates_URL,
} = ApiURLConstants;


export const members = {
    getConfigObject: (memberID: number, configType: number): Promise<any> => {
        return axios.get(`${GetConfigObject_URL}?memberId=${memberID}&configType=${configType}`)
            .then(responseBody)
    },

    deleteMemberBlocks: (membersBlockedIDs: number[]): Promise<any> => {
        return axios.delete(`${DeleteMemberBlocks_URL}`, { data: membersBlockedIDs })
            .then(responseBody)
    },

    putAddMembersBlock: (payload: AddMembersBlockModel): Promise<any> => {
        return axios.put(`${PutAddMembersBlock_URL}`, payload)
            .then(responseBody)
    },

    postAddMemberFavourite: (payload: AddMemberFavouriteModel): Promise<any> => {
        return axios.post(`${PostAddMemberFavourite_URL}`, payload)
            .then(responseBody)
    },

    putUpdateMemberFavourite: (payload: AddMemberFavouriteModel): Promise<any> => {
        return axios.put(`${PutUpdateMemberFavourite_URL}`, payload)
            .then(responseBody)
    },

    deleteMemberFavourites: (membersFavouritesIDs: number[]): Promise<any> => {
        return axios.delete(`${DeleteMemberFavourites_URL}`, { data: membersFavouritesIDs })
            .then(responseBody)
    },

    getMemberFavourites: (memberID: number): Promise<any> => {
        return axios.get(`${GetMemberFavourites_URL}?memberID=${memberID}`)
            .then(responseBody)
    },

    postAddConfigObject: (params: any): Promise<any> => {
        return axios.post(`${PostAddConfigObject_URL}`, params, jsonConfig)
            .then(responseBody)
    },

    putUpdateConfigObject: (params: any): Promise<any> => {
        return axios.put(`${PutUpdateConfigObject_URL}`, params, jsonConfig)
            .then(responseBody)
    },

    getSupplyingMembersByUserNameAndServiceType: (userName: string, serviceType?: ServiceType): Promise<any> => {
        return axios.get(`${GetSupplyingMembersByUserNameAndServiceType_URL}?userName=${userName}&serviceType=${serviceType || ''}`)
            .then(responseBody)
    },

    getMembers: (params: MembersRequestParam): Promise<any> => {
        return axios.post(`${GetMembers_URL}`, params, jsonConfig)
            .then(responseBody)
    },

    exportMembersToCSV: (params: MembersRequestParam): Promise<any> => {
        return axios.post(`${ExportMembersToCsv_URL}`, params, jsonConfig)
            .then(responseBody)
    },

    addMember: (params: AddMemberRequestModel): Promise<any> => {
        return axios.post(`${PostAddMember_URL}`, params, jsonConfig)
            .then(responseBody)
    },

    updateMember: (params: AddMemberRequestModel): Promise<any> => {
        return axios.put(`${PutUpdateMember_URL}`, params, jsonConfig)
            .then(responseBody)
    },

    getMember: (memberID: number): Promise<any> => {
        return axios.get(`${GetMember_URL}?memberId=${memberID}`)
            .then(responseBody)
    },

    getNewMembers: (params: MembersRequestParam): Promise<any> => {
        return axios
            .post(`${GetNewMembers_URL}`, params, jsonConfig)
            .then(responseBody);
    },

    postAddSelectedMemberToPartner: (params: any): Promise<any> => {
        return axios
            .post(`${PostAddSelectedMemberToPartner_URL}`, params, jsonConfig)
            .then(responseBody);
    },

    deleteSelectedPartnersFromMember: (payload: DeleteMemberPartnersRequestModel): Promise<any> => {
        return axios.delete(`${DeleteSelectedPartnersFromMember_URL}`, { data: payload })
    },

    getMembersForDropdown: (memberType?: MemberType): Promise<any> => {
        if(memberType === undefined){
            return axios.get(`${GetMembersForDropdown_URL}`)
            .then(responseBody)
        }else{
            return axios.get(`${GetMembersForDropdown_URL}?memberType=${memberType}`)
            .then(responseBody)
        }
    },

    getPartnersForDropdown: (): Promise<any> => {
        return axios
            .get(`${GetPartnersForDropdown_URL}`)
            .then(responseBody)
    },
    
    getMembersBy: (getMembersByRequestFilter : GetMembersByRequestFilter): Promise<any> => {
        return axios
            .get(`${GetMembersBy_URL}?FilterType=${getMembersByRequestFilter.filterType}&FilterValue=${getMembersByRequestFilter.filterValue}`)
            .then(responseBody)
    },

    postAddTradePlates: (payload: AddTradePlateRequest): Promise<any> => {
        return axios.post(`${PostAddTradePlates_URL}`, payload)
            .then(responseBody)
            .catch(error => {
                return Promise.reject(error);
            });
    },

    postAddTransporterPlates: (payload: AddTransporterPlateRequest): Promise<any> => {
        return axios.post(`${PostAddTransporterPlates_URL}`, payload)
            .then(responseBody)
            .catch(error => {
                return Promise.reject(error);
            });
    },

    putUpdateTransporterPlates: (payload: UpdateTransporterPlateRequest): Promise<any> => {
        return axios.put(PutUpdateTransporterPlates_URL, payload)
            .then(responseBody)
            .catch(error => {
                return Promise.reject(error);
            });
    },

    putUpdateTradePlates: (payload: UpdateTradePlateRequest): Promise<any> => {
        return axios.put(PutUpdateTradePlates_URL, payload)
            .then(responseBody)
            .catch(error => {
                return Promise.reject(error);
            });
    },

    deleteTradePlate: (payload: DeleteTradePlateRequest): Promise<any> => {
        return axios.delete(DeleteTradePlate_URL, { data: payload })
            .then(responseBody)
            .catch(error => {
                return Promise.reject(error);
            });
    },

    deleteTransporterPlate: (payload: DeleteTransporterPlateRequest): Promise<any> => {
        return axios.delete(DeleteTransporterPlate_URL, { data: payload })
            .then(responseBody)
            .catch(error => {
                return Promise.reject(error);
            });
    },

    getSupplierInsuranceRenewalDates: (memberId: number): Promise<any> => {
        return axios.get(`${GetSupplierInsuranceRenewalDates_URL}?memberID=${memberId}`)
            .then(responseBody)
            .catch(error => {
                return Promise.reject(error);
            });
    },
}
