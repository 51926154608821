import { DataGrid, GridSortModel } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import {
     ControlledPagination as Pagination,
     ModalDialog,
     ShowPleaseWait,
     useAlert,
} from '../../../../../Components';
import {
     PaymentItemModel,
     UpdateProofStatusModel,
     ViewPaymentsResponseModel,
} from '../../../../../Models/Jobs/Payments';
import { GridProps, Sorter } from '../../../../../Types';
import { getSortModelFromSorter } from '../../../../../Utils';
import { ProofStatus, ProofType } from '../../../../../Utils/Enums';
import { useUpdateProofStatus } from '../Hooks';
import { ChangeDueDateModalBody } from '../Modals.tsx/ChangeDueDateModal';
import { PausePaymentModalFooter } from '../Modals.tsx/PausePaymentModal';
import { PaymentNotesModalBody } from '../Modals.tsx/PaymentNotesModal';
import { paymentsColumns } from './Columns';
import { getRowColor } from './style/paymentGridStyleHelper';
import { PayNowModal } from '../Modals.tsx/PayNowModal';
import { RefundPrePaymentModal } from '../Modals.tsx/RefundPrePayment';
import { MarkPaymentAsNotPayableModal } from '../Modals.tsx/MarkPaymentAsNotPayable';
import { CheckPaymentModal } from '../Modals.tsx/CheckPaymentModal';
import { UncheckPaymentModal } from '../Modals.tsx/UncheckPaymentModal';

const GridView: React.FC<GridViewProps> = ({
     data: paymentList,
     onPageChange,
     onPageSizeChange,
     onSort,
     onFetchData
}) => {
     const [showPleaseWait, setShowPleaseWait] = useState<boolean>(false);
     const { alert, setAlert } = useAlert();

     const {
          mutate: updateProofStatusMutate,
          data: putUpdateProofStatusData,
          error: putUpdateProofStatusError,
     } = useUpdateProofStatus();

     useEffect(() => {
          setShowPleaseWait(false);
          if (putUpdateProofStatusError) {
               setAlert({
                    ...alert,
                    header: putUpdateProofStatusError.ErrorCode === 8220 
                         ? 'Cannot Archive Report'
                         : putUpdateProofStatusError.Subject ?? 'Error',
                    body: putUpdateProofStatusError.ErrorCode === 8220
                         ? 'The inspection report cannot be archived because the job is locked.'
                         : putUpdateProofStatusError.Description ?? 'Error when updating POC or POD.',
                    show: true,
               }); 
          }
     }, [putUpdateProofStatusError]);

     useEffect(() => {
          setShowPleaseWait(false);
          if (putUpdateProofStatusData) {
               setAlert({
                    ...alert,
                    header: putUpdateProofStatusData,
                    body: `${putUpdateProofStatusData} Successfully`,
                    show: true,
               });
               onFetchData();
          }
     }, [putUpdateProofStatusData]);

     useEffect(() => {

     }, []);

     const showPaymentNotesModule = (paymentId: number, note: string) => {
          setAlert({
               ...alert,
               show: true,
               header: 'Add / Update Payment Notes',
               body: (
                    <PaymentNotesModalBody
                         paymentId={paymentId}
                         paymentNote={note}
                         onClose={alert.closeCallback}
                         onRefresh={onFetchData}
                    />
               ),
          });
     };

     const showPaymentDueDateModal = (paymentId: number) => {
          setAlert({
               ...alert,
               show: true,
               header: 'Change Due Date',
               body: (
                    <ChangeDueDateModalBody
                         paymentId={paymentId}
                         onClose={alert.closeCallback}
                         onRefresh={onFetchData}
                    />
               ),
          });
     };

     const showPayNowModal = (paymentId: number, paymentData: PaymentItemModel) => {
          setAlert({
               ...alert,
               show: true,
               header: 'Pay Now - Pease Enter Amount',
               body: (
                    <PayNowModal
                         paymentId={paymentId}
                         onClose={alert.closeCallback}
                         onRefresh={onFetchData}
                         paymentData={paymentData}
                    />
               ),
          });
     };

     const showRefundPrePaymentModal = (paymentId: number, paymentData: PaymentItemModel) => {
          setAlert({
               ...alert,
               show: true,
               header: 'Refund Pre Payment',
               body: (
                    <RefundPrePaymentModal
                         paymentId={paymentId}
                         onClose={alert.closeCallback}
                         onRefresh={onFetchData}
                    />
               ),
          });
     };

     const showMarkPaymentAsNotPayableModal = (paymentId: number, paymentData: any) => {
          setAlert({
               ...alert,
               show: true,
               header: 'Mark Payment As Not Payable',
               body: (
                    <MarkPaymentAsNotPayableModal
                         paymentId={paymentId}
                         onClose={alert.closeCallback}
                         onRefresh={onFetchData}
                    />
               ),
          });
     };

     const showCheckPaymentModal = (paymentId: number, paymentData: any) => {
          setAlert({
               ...alert,
               show: true,
               header: 'Check Payment',
               body: (
                    <CheckPaymentModal
                         paymentId={paymentId}
                         onClose={alert.closeCallback}
                         onRefresh={onFetchData}
                    />
               ),
          });
     };

     const showUncheckPaymentModal = (paymentId: number, paymentData: any) => {
          setAlert({
               ...alert,
               show: true,
               header: 'Uncheck Payment',
               body: (
                    <UncheckPaymentModal
                         paymentId={paymentId}
                         onClose={alert.closeCallback}
                         onRefresh={onFetchData}
                    />
               ),
          });
     };
 

     const showPausePaymentModal = (paymentId: number) => {
          setAlert({
               ...alert,
               show: true,
               header: 'Pause Payment',
               body: (
                    <PausePaymentModalFooter
                         onClose={alert.closeCallback}
                         paymentId={paymentId}
                         onRefresh={onFetchData}
                    />
               ),
          });
     };

     const onArchivePOC = (id: number) => {
          setShowPleaseWait(true);
          const payload: UpdateProofStatusModel = {
               Id: id,
               Status: ProofStatus.Live,
               ProofType: ProofType.POC,
          };
          updateProofStatusMutate(payload);
     };

     const onArchivePOD = (id: number) => {
          setShowPleaseWait(true);
          const payload: UpdateProofStatusModel = {
               Id: id,
               Status: ProofStatus.Live,
               ProofType: ProofType.POD,
          };
          updateProofStatusMutate(payload);
     };

     const handlePageChange = (
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
          currentPage: number
     ) => {
          onPageChange(currentPage);
     };

     const handleRowsPerPageChange = (
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
     ) => {
          onPageSizeChange(parseInt(e.target.value));
     };

     const handleSort = (sortModel: GridSortModel) => {
          onSort(sortModel);
     };

     const columns = paymentsColumns(
          showPausePaymentModal,
          showPaymentNotesModule,
          showPaymentDueDateModal,
          showPayNowModal,
          showRefundPrePaymentModal,
          showMarkPaymentAsNotPayableModal,
          onArchivePOC,
          onArchivePOD,
          setAlert,
          alert.closeCallback,
          showCheckPaymentModal,
          showUncheckPaymentModal
     );

     if (paymentList?.Page) {
          const { CurrentPage, ItemsPerPage, TotalItems, Items, TotalPages } = paymentList.Page;
          const CustomGridFooter: React.FC = (): JSX.Element => {
               return (
                    <div>
                         <Pagination
                              itemsCount={TotalItems}
                              totalPages={TotalPages}
                              pageSize={ItemsPerPage}
                              currentPage={CurrentPage}
                              onPageChange={handlePageChange}
                              onRowsPerPageChange={handleRowsPerPageChange}
                         />
                    </div>
               );
          };
          return (
               <div>
                    <ShowPleaseWait show={showPleaseWait} />
                    <ModalDialog {...alert}></ModalDialog>
                    {paymentList?.Page && (
                         <div id='id_grid_payments'>
                                   <DataGrid
                                        sortingOrder={['desc', 'asc']}
                                        getRowClassName={(params) =>
                                             getRowColor(parseInt(params.row.PaymentStatus))
                                        }
                                        getRowId={(row: any) => row.PaymentID}
                                        autoHeight
                                        rows={Items}
                                        rowHeight={72}
                                        disableColumnMenu={true}
                                        columns={columns}
                                        columnBuffer={columns.length + 1}
                                        components={{
                                             Toolbar: CustomGridFooter,
                                             Footer: CustomGridFooter,
                                        }}
                                        disableExtendRowFullWidth={false}
                                        sortingMode='server'
                                        sortModel={getSortModelFromSorter(
                                             paymentList?.TableSorter as Sorter
                                        )}
                                        onSortModelChange={handleSort}
                                   />
                         </div>
                    )}
               </div>
          );
     }
     return null;
};

export default GridView;

interface GridViewProps extends GridProps<ViewPaymentsResponseModel> {
     onFetchData: Function
}
